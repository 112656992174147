"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eventTypeInvariant = void 0;
var _invariant = _interopRequireDefault(require("../../utils/invariant"));
var eventTypeConstants = _interopRequireWildcard(require("../constants/eventTypeConstants"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const eventTypeValues = Object.values(eventTypeConstants);
const eventTypeInvariant = potentialEventType => (0, _invariant.default)(eventTypeValues.indexOf(potentialEventType) !== -1, 'Expected a valid event type but received %s. Valid event types include %s.', potentialEventType, eventTypeValues);
exports.eventTypeInvariant = eventTypeInvariant;